<template>
    <div class="ticket-container">
      <header-bloc
        :color="'#2b6df1'" :colorText="'white'"
        :title="customer ? 'Ticket : ' + customer.firstName + ' ' + customer.lastName : 'Ticket'"
      ></header-bloc>
      <transaction-details />
      <div class="footer-section">
        <div class="border-bottom-grey"/>
        <b-row style="margin-top: 5px; margin-bottom: 5px;">
          <b-col class="col-divider-6">
            <b-row>
              <b-col class="ml-3 total-amount mt-1" cols="12">
                {{ $t('views.client.details.body.left.subscription-affect.total-rate') }}
              </b-col>
            </b-row>
          </b-col>
          <div
            class="split-layout__divider"
          >
            <div class="split-layout__rule"></div>
            <div class="split-layout__rule"></div>
          </div>
          <b-col
            class="col-divider-5"
          >
            <b-row>
              <b-col
                cols="12"
                class="pr-4"
                align="right"
                :class="restToPay <= 0 ? 'rest-to-give-back' : 'rest-to-pay'"
              >
                {{ restToPay <= 0 ? parseFloat($store.getters["nextore/getRendu"]).toFixed(2) : parseFloat(Math.abs(restToPay)).toFixed(2) }} {{ $currency }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <numeric-input
          :show-pave="true"
          :selected-payment-method="selectedPaymentMethod"
        />

        <template v-for="row in paymentMethods">
        <div class="payments-icon">
            <div
              v-for="payMethod in row"
              class="payment-icon"
              @click="onPaymentMethodClick(payMethod.code)"
            >
            <span>
              {{ payMethod.name }}
            </span>
            </div>
        </div>
        </template>

      </div>
    </div>
</template>

<script>
import {SUCCESS, DANGER} from "@plugins/flash";
import {addSale} from "@api/nextore/services/sale.api";
import {randomString} from "@/utils/form";
import {floatToFixed} from "@/utils/string";

const MODAL_ID = 'CHECKOUT_ID';

export default {
  data: () => ({
    showPave: true,
    paymentMethods: [
      [
        {name: 'Carte bancaire', code: 'card'},
        {name: 'Espèces', code: 'cash'},
        {name: 'Chèque', code: 'check'}
      ],
      [
        {name: 'ALMA', code: 'alma'},
        {name: 'ANCV', code: 'ancv'},
        {name: 'Coupon Sport', code: 'sport'}
      ]
    ]
  }),
  components: {
    HeaderBloc: () => import('@custom/caisse/HeaderBloc'),
    NumericInput: () => import('@views/Nextore/NumericInput'),
    TransactionDetails: () => import('@views/Nextore/details/Index')
  },
  props: {
    selectedRate: {
      type: Object,
      default: () => ({
        values: null,
        selected: false,
      })
    },
    selectedPaymentMethod: {
      type: Object,
      default: () => ({
        values: [],
        method: null,
        value: 0,
        selected: false,
      }),
    },
    selectedSubscription: {
      type: Object,
      default: () => {
      },
    },
    display: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: MODAL_ID,
    }
  },
  computed: {
    customer() {
      return this.$store.getters["nextore/getCustomer"];
    },
    isValidated() {
      return (this.$store.getters["nextore/getRestToPay"] > 0);
    },
    products() {
      return this.$store.getters["nextore/getProducts"];
    },
    keyboardText() {
      if (!this.isValidated) {
        return 'general.actions.dismiss';
      } else {
        return 'general.actions.validate';
      }
    },
    paymentLabel() {
      if (this.restToPay < 0 || this.$store.getters["nextore/getRendu"] > 0) {
        return this.$t('views.client.details.body.left.subscription-affect.rest-to-give-back')
      } else {
        return this.$t('views.client.details.body.left.subscription-affect.rest-to-pay')
      }
    },
    restToPay() {
      return this.$store.getters["nextore/getRestToPay"];
    }
  },
  methods: {
    onKeyboardBtnClick() {
      return !this.isValidated ? this.$emit('hidden') : this.$emit('hidden');
    },
    onHide() {
      this.showPave = false;
    },
    onClick() {
      this.showPave = false;
    },
    onPaymentMethodClick(method) {
      this.$store.dispatch("nextore/addPayment", {method, value: 0});
      if (this.$store.getters["nextore/getRestToPay"] <= 0) {
        this.proceedToPayment();
      }
    },
    beforeHide () {
      this.$store.dispatch("nextore/setRendu", 0);
      this.$emit('hidden');
    },
    proceedToPayment() {
      const products = this.$store.getters["nextore/getProducts"];
      const payments = this.$store.getters["nextore/getPayments"];

      let sale = {
        "info_cmd": {
          "customer_id": this.customer ? this.customer.nextoreCustomerReference + '' : null,
          "customer": this.customer ? this.customer.fullName : null,
          "staff_note": "",
        }
      }
      let totalPayment = 0;
      payments.forEach(payment => {
        totalPayment = totalPayment + parseFloat(payment.value);
        const amount = floatToFixed(payment.value, 2) + '';

        switch (payment.method) {
          case 'ancv': {
            sale ['paieid-' + randomString()] = {
              type: 'CHEQUE VACANCES',
              value: amount,
              note: ""
            }
            break;
          }
          case 'sport': {
            sale ['paieid-' + randomString()] = {
              type: 'COUPON SPORT',
              value: amount,
              note: ""
            }
            break;
          }
          case 'card': {
            sale ['paieid-' + randomString()] = {
              type: 'CARTE BANCAIRE',
              value: amount,
              note: ""
            }
            break;
          }
          case 'cash': {
            sale ['paieid-' + randomString()] = {
              type: 'ESPECES',
              value: amount,
              note: ""
            }
            break;
          }
          case 'check': {
            sale ['paieid-' + randomString()] = {
              type: 'CHEQUE',
              value: amount,
              note: ""
            }
            break;
          }
          case 'sport-ticket': {
            sale ['paieid-' + randomString()] = {
              type: 'COUPON SPORT',
              value: amount,
              note: ""
            }
            break;
          }
          case 'alma': {
            sale ['paieid-' + randomString()] = {
              type: 'ALMA',
              value: amount,
              note: ""
            }
            break;
          }
        }
      });

      let totalAmount = 0;
      products.forEach(product => {
        totalAmount = totalAmount + ( parseFloat(product.price) * parseFloat(product.quantity));
        const amount = floatToFixed(product.price, 2) + '';
        sale ['prodid-' + randomString()] = {
          "id": product.id + '',
          "name": product.name,
          "price": amount + '',
          "quantity": product.quantity + '',
        }
      });

      // const rendu = totalPayment - totalAmount;
      // this.$store.dispatch("nextore/setRendu", rendu);

      addSale(sale)
        .then((response) => {
          if (response.data !== 'false') {
            this.$flash(null, this.$t('general.actions.success-sale'), 5000, SUCCESS);
            this.$store.dispatch('nextore/clearSale');
            if (this.$store.getters["nextore/rendu"] <= 0) {
              this.$emit('hidden');
            }
            this.restToPay = 0 - this.$store.getters["nextore/rendu"];
          } else {
            this.$flash(null, this.$t('general.actions.error-notification'), 5000, DANGER);
            // TODO : manage error
          }
        });
    }
  },
}
</script>
<style scoped lang="scss">


/deep/ .header-modal-checkout {
  background-color: #ffffff;
  padding-bottom: 20px;
  border-bottom: 2px solid #EBF2F7;
}


.modal-name {
  padding-top: 15px;
  font: normal normal 900 18px Avenir;
}
.payment-type {
  padding-left: 1.5rem;
  font: normal normal normal 15px Avenir;
}

.product-item {
  font: normal normal normal 15px Avenir;
}

.customer-name {
  font-weight: 700;
}

/deep/ .close-icon {
  display: none;
}

.width-auto {
  width: -webkit-fill-available;
}

.footer-section {
  bottom: 60px;
  width: 100%;
  height: fit-content;
}

.border-radius-0 {
  border-radius: 0;
}

.total-amount {
  font: normal normal bold 25px Avenir;
  letter-spacing: -0.33px;
  color: #2E2E2E;
}

.icon-label {
  font: normal normal normal 17px Avenir;
  letter-spacing: -0.33px;
  color: #2E2E2E;

  &:hover {
    color: #092772;
  }
}

.active-label {
  font: normal normal bold 17px Avenir;
  letter-spacing: -0.33px;
  color: #092772;
}

.active-icon {
  color: #092772;
}

.price-total {
  font: normal normal bold 25px Avenir;
  letter-spacing: -0.47px;
  color: #092772;
}

.rest-to-pay {
  font: normal normal bold 25px Avenir;
  letter-spacing: -0.47px;
  color: #c80523;
  padding-right: 40px;
}

.rest-to-give-back {
  font: normal normal bold 25px Avenir;
  letter-spacing: -0.47px;
  color: #4eb009;
  padding-right: 40px;
}

.col-divider-6 {
  flex: 0 0 54%;
  max-width: 54%;
}

.payment-icon {
  cursor: pointer;
  width: calc(100% / 3);
  text-align: center;
  &:hover {
    color: #092772;
  }
  &.disabled {
    cursor: unset;
  }
}

.font-text-payment {
  font: normal normal bold 18px Avenir;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.flex-row {
  max-height: calc(100vh - 520px);
  overflow-y: scroll;
}

.ticket-container {
  box-shadow: 0 0 10px 5px rgba(145,145,145,0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  .footer-section {
    position: absolute;
    width: 100%;
    bottom: 0;
    .payments-icon {
      display: inline-flex;
      text-align:center;
      width: 100%;
      position: relative;
      .payment-icon {
        border-radius: 5px;
        margin: 2px;
        background-color: limegreen;
        min-width: calc(calc(100% - 12px) / 3);
        max-width: calc(calc(100% - 12px) / 3);
        overflow-x: unset;
        min-height: 70px;
        max-height: 70px;
        display: inline-grid;
        align-items: center;
        span {
          width: 100%;
          text-align: center;
          color: white;
          font-size: 1.3rem;
          font-weight: 800;
          margin: auto;
          line-height: 1;
        }
      }
    }
  }
}
</style>
